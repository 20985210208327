<template>
  <header class="feed-manager__header">
    <div class="feed-manager__head">
      <h1 class="feed-manager__title" v-text="translate('main.header.title')" />
      <mds-button
        variation="icon-only"
        icon="question-circle"
        type="button"
        target="_blank"
        :href="supportUrl"
      />
    </div>

    <mds-tabs class="feed-manager__nav">
      <mds-tabs-item
        v-for="(tab, index) in tabs"
        :key="index"
        :active="tab.isActive"
        @mds-tabs-item-active="$emit('tabClick', tab.id)"
      >
        {{ translate(tab.name) }}
      </mds-tabs-item>
    </mds-tabs>
  </header>
</template>
<script>
import { MdsTabs, MdsTabsItem } from '@mds/tabs'
import { MdsButton } from '@mds/button'
import MwcBaseHelpersMixin from '@morningstar/mcl-utilities/src/vue/mixins/mwc-base-mixin'
import defaultConfig from '../config/i18n'

export default {
  name: 'FeedManagerHeader',
  components: { MdsTabs, MdsTabsItem, MdsButton },
  mixins: [MwcBaseHelpersMixin],
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      defaultConfig,
    }
  },
  computed: {
    supportUrl() {
      return process.env.SUPPORT_URL
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@mds/constants';
@import '@mds/typography';

.feed-manager {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $mds-space-1-and-a-half-x;
  }

  &__title {
    @include mds-level-4-heading();

    font-weight: bold;
    margin: 0;
  }

  &__nav {
    padding: 0 $mds-space-1-and-a-half-x;
  }
}
</style>
