import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import { Store } from 'vuex'
import store from '@/store'
import { ICacheState } from '@/types/cache'
import { v4 as uuidv4 } from 'uuid'
import {
  getTokenFromCookie,
  createRefreshToken,
} from '@/services/tokenDataProvider'
import { CustomAxiosError } from '@/services/types/api'

const feedManagerApi: AxiosInstance = axios.create({
  baseURL: `${process.env.DIRECT_FEED_MANAGER_BACKEND_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'X-API-RequestId': uuidv4(),
  },
})

const alProxyApi: AxiosInstance = axios.create({
  baseURL: `${process.env.AL_PROXY_API_BASE_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-API-RequestId': uuidv4(),
  },
})

const alApi: AxiosInstance = axios.create({
  baseURL: `${process.env.AL_API_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'X-API-RequestId': uuidv4(),
  },
})

const refreshTokenApi: AxiosInstance = axios.create({
  baseURL: `${process.env.DIRECT_FEED_MANAGER_BACKEND_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'X-API-RequestId': uuidv4(),
  },
})

const apiHeaderInterceptor = async (config: InternalAxiosRequestConfig) => {
  const cacheStore = store as Store<ICacheState>
  const { token, authCode, refreshTokenExists } = cacheStore.state.cache.cache

  const validToken = token || (await getTokenFromCookie())
  const refreshToken =
    refreshTokenExists || sessionStorage.getItem('FEED_MANAGER_REFRESH_TOKEN')

  if (!token) {
    cacheStore.commit('SET_TOKEN', validToken)
  }

  if (!JSON.parse(refreshToken)) {
    await createRefreshToken(
      cacheStore,
      validToken,
      authCode,
      window.location.href
    )
  }

  config.headers['Authorization'] = `Bearer ${validToken}`
  return config
}

const validateTokenInterceptor = async (
  instance: AxiosInstance,
  error: CustomAxiosError
) => {
  const {
    config: originalRequest,
    code: errorCode = '',
    response: errorResponse = {
      status: 400,
    },
  } = error

  if (
    error &&
    (errorResponse?.status === 401 || errorCode === 'ERR_NETWORK') &&
    !originalRequest.retry
  ) {
    console.log("The token has expired. Let's create a new one from the cookie")
    const newToken = await getTokenFromCookie()

    if (newToken) {
      const cacheStore = store as Store<ICacheState>
      const { authCode } = cacheStore.state.cache.cache

      cacheStore.commit('SET_TOKEN', newToken)

      await createRefreshToken(
        cacheStore,
        newToken,
        authCode,
        window.location.href
      )

      instance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
      originalRequest.headers['Authorization'] = `Bearer ${newToken}`

      originalRequest.retry = true

      // Call the instance again with the new token attached
      return instance(originalRequest)
    }
  }

  return Promise.reject(error)
}

// Request Interceptor responsible for verifying and adding the token
feedManagerApi.interceptors.request.use(async (config) =>
  apiHeaderInterceptor(config)
)
alApi.interceptors.request.use(async (config) => apiHeaderInterceptor(config))

// Response Interceptor responsible for validating the current token and regenerating it if necessary
feedManagerApi.interceptors.response.use(
  (response) => response,
  async (error) => validateTokenInterceptor(feedManagerApi, error)
)
alApi.interceptors.response.use(
  (response) => response,
  async (error) => validateTokenInterceptor(alApi, error)
)

export { feedManagerApi, alApi, alProxyApi, refreshTokenApi }
