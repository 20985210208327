import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import feedManagerView from '@/views/FeedManagerView.vue'
import { authGuard } from './authGuard'
import { LOCAL } from '../config/constants'

Vue.use(VueRouter)

// The earlier a route is defined in this list, the higher the priority it gets
const routes: Array<RouteConfig> = [
  {
    path: '/scheduler',
    component: () =>
      import(
        /* webpackChunkName: "feed-creation-page" */ '../views/FeedCreationPage.vue'
      ),
    beforeEnter: async (to, from, next) => {
      if (process.env.TARGET_ENV !== LOCAL) {
        authGuard(to, from, next)
      }
      next()
    },
  },
  // {
  //   path: '/refresh-token',
  //   redirect: () => {
  //     const pathname = window.location.pathname;
  //     const searchParams = new URLSearchParams(window.location.search);
  //     const newPath = `${pathname}${searchParams.toString()}`;
  //     const redirectUrl = encodeURIComponent(window.location.href)
  //     const params = `client_id=${process.env.CLIENT_ID}&response_type=code&scope=offline_access&redirect_uri=${redirectUrl}`
  //     const uimRefreshTokenUrl = `${process.env.UIM_ISSUER}/authorize?${params}`
  //     window.location.href = uimRefreshTokenUrl
  //     return newPath
  //   },
  // },
  {
    path: '/',
    component: feedManagerView,
    beforeEnter: async (to, from, next) => {
      if (process.env.TARGET_ENV !== LOCAL) {
        authGuard(to, from, next)
      }
      next()
    },
    children: [
      {
        path: '/:view',
        component: () =>
          import(
            /* webpackChunkName: "feed-manager-page" */ '../views/FeedManagerPage.vue'
          ),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
