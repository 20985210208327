var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mds-notification-container",
    {
      staticClass: "feed-manager__notifications",
      attrs: { position: "top-center" },
    },
    _vm._l(_vm.notifications, function (notification, id) {
      return _c(
        "mds-notification",
        {
          key: id,
          staticClass: "feed-manager__notification",
          attrs: {
            size: "small",
            variation: notification.variation,
            title: _vm.translate(notification.title),
          },
          on: {
            "mds-notification-dismissed": function ($event) {
              return _vm.onDismiss(notification)
            },
          },
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.translate(notification.description)) + "\n  "
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }