var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "feed-manager" },
    [
      _c("LimitedAccessBanner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.limitedAccess,
            expression: "limitedAccess",
          },
        ],
      }),
      _vm._v(" "),
      _c("FeedManagerHeader", {
        attrs: { tabs: _vm.tabs },
        on: { tabClick: _vm.handleTabClick },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "feed-manager__wrapper",
          class: {
            "feed-manager__wrapper--full-height": !_vm.limitedAccess,
          },
        },
        [_c("router-view", { staticClass: "feed-manager__content" })],
        1
      ),
      _vm._v(" "),
      _c("feed-manager-notifications", {
        attrs: { notifications: _vm.notifications },
        on: { dismissed: _vm.removeNotification },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }