<template>
  <div class="feed-manager">
    <LimitedAccessBanner v-show="limitedAccess" />
    <FeedManagerHeader :tabs="tabs" @tabClick="handleTabClick" />
    <div
      class="feed-manager__wrapper"
      :class="{
        'feed-manager__wrapper--full-height': !limitedAccess,
      }"
    >
      <router-view class="feed-manager__content" />
    </div>
    <feed-manager-notifications
      :notifications="notifications"
      @dismissed="removeNotification"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import FeedManagerHeader from '@/components/FeedManagerHeader.vue'
import FeedManagerNotifications from '../components/FeedManagerNotifications.vue'
import LimitedAccessBanner from '../components/limitedDistribution/LimitedAccessBanner.vue'
import { TABS, FEEDS_TAB } from '@/config/tabs'
import { FULL_ACCESS } from '@/components/limitedDistribution/config/constants'

export default {
  name: 'FeedManager',
  components: {
    FeedManagerHeader,
    FeedManagerNotifications,
    LimitedAccessBanner,
  },
  data() {
    return {
      tabs: TABS,
    }
  },
  async created() {
    // store the authCode from UIM required to later create a refresh token
    const authCode = this.$route.query['code']
    if (authCode) {
      this.$store.dispatch('setAuthCode', authCode)
    }

    const currentTab = this.$route.params.view ?? FEEDS_TAB
    this.navigateToRoute(currentTab)

    this.tabs = this.tabs.map((tab) => ({
      ...tab,
      isActive: tab.id === currentTab,
    }))
  },
  computed: {
    ...mapState({
      cache: (state) => state.cache.cache,
    }),
    notifications() {
      return this.cache.notifications ?? []
    },
    settings() {
      return this.cache.userSettings
    },
    limitedAccess() {
      // If the user does not have settings (empty object), it does mean the user doesn't have the Direct License
      if (this.settings) {
        if (
          isEmpty(this.settings) &&
          !this.settings.data_unity_workspace_access
        ) {
          return true
        }

        /* Until we retrieve the user settings from the endpoint, let's set those values
      as full access to hide the banner. Once we have the real values, the banner will
      listen to this computed changes. */
        const {
          data_unity_pr_access = FULL_ACCESS,
          data_unity_workspace_access = FULL_ACCESS,
        } = this.settings

        return (
          data_unity_pr_access !== FULL_ACCESS ||
          data_unity_workspace_access !== FULL_ACCESS
        )
      }

      return false
    },
  },
  methods: {
    handleTabClick(tabId) {
      // Listen for every tab click to update their state and render the corresponding component
      this.navigateToRoute(tabId)

      this.tabs = this.tabs.map((tab) => ({
        ...tab,
        isActive: tab.id === tabId,
      }))
    },
    navigateToRoute(name) {
      // Validate whether the router is already in the specified route name to avoid rendering issues
      const currentPath = this.$route.path
      const newPath = `/${name}`

      if (currentPath !== newPath) {
        this.$router.push(newPath)
      }
    },
    async removeNotification(index) {
      this.$store.dispatch('removeNotification', index)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@mds/constants';

.feed-manager {
  &__wrapper {
    padding: $mds-space-1-and-a-half-x;
    background-color: $mds-color-neutral-90;
    height: calc(100vh - 146px);

    &--full-height {
      height: calc(100vh - 105px);
    }
  }

  &__content {
    background-color: $mds-color-white;
    border-radius: $mds-border-radius-panel;
    height: calc(100% - 10px);
    overflow-y: scroll;
  }
}
</style>
