import { alProxyApi, refreshTokenApi } from './api'
import router from '@/router'

export const checkRefreshTokenExists = async (
  token: string
): Promise<boolean> => {
  try {
    const response = await refreshTokenApi.get('/check-refresh-token', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data
  } catch (error) {
    // NOTE: in the case of an error from the backend, err on the side of not
    // creating the refresh token, so return true, which means we will not
    // create the refresh token.
    return true
  }
}
export const createRefreshToken = async (
  store: any,
  token: string,
  authCode: string,
  callbackUrl: string
) => {
  const data = {
    auth_code: authCode,
    call_back_url: callbackUrl,
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const refreshTokenExists = await checkRefreshTokenExists(token)
  store.commit('SET_REFRESH_TOKEN_CHECK', refreshTokenExists)

  if (!refreshTokenExists) {
    if (authCode) {
      await refreshTokenApi.post('/create-refresh-token', data, config)
      store.commit('SET_REFRESH_TOKEN_CHECK', true)
    } else {
      // router.push('/refresh-token')
      const redirectUrl = encodeURIComponent(window.location.href)
      const params = `client_id=${process.env.CLIENT_ID}&response_type=code&scope=offline_access&redirect_uri=${redirectUrl}`
      const uimRefreshTokenUrl = `${process.env.UIM_ISSUER}/authorize?${params}`
      window.location.href = uimRefreshTokenUrl
    }
  }
}

export const getTokenFromCookie = async (): Promise<string> => {
  try {
    const { data = '' } = await alProxyApi.get(
      '/http-only/uim/v2/public/user/maas-access-token'
    )
    return data
  } catch (error) {
    return ''
  }
}
