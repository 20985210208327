<template>
  <div id="app">
    <mds-loader class="loader__feedback" v-if="!token && !isLocal" />
    <router-view v-else> </router-view>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MdsLoader from '@mds/loader'
import { LOCAL } from './config/constants'

export default {
  name: 'FeedManagerApp',
  components: {
    MdsLoader,
  },
  computed: {
    ...mapState({
      cache: (state) => state.cache.cache,
    }),
    token() {
      return !!this.cache.token
    },
    isLocal() {
      return process.env.TARGET_ENV === LOCAL
    },
  },
}
</script>
<style>
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.loader__feedback {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
