<template>
  <div class="banner">
    <p class="banner__description" v-text="bannerDescription" />
    <mds-button
      on-dark
      class="banner__button"
      icon="chat-bubble"
      variation="secondary"
      target="_blank"
      :href="DATA_DISTRIBUTION_URL"
      :text="buttonLabel"
    />
  </div>
</template>

<script>
import { MdsButton } from '@mds/button'
import defaultConfig from '@/components/limitedDistribution/config/i18n'
import MwcBaseHelpersMixin from '@morningstar/mcl-utilities/src/vue/mixins/mwc-base-mixin'
import { DATA_DISTRIBUTION_URL } from '@/components/limitedDistribution/config/constants'

export default {
  name: 'LimitedAccessBanner',
  components: {
    MdsButton,
  },
  mixins: [MwcBaseHelpersMixin],
  data() {
    return {
      defaultConfig,
      DATA_DISTRIBUTION_URL,
    }
  },
  computed: {
    bannerDescription() {
      return this.translate('limitedAccess.banner.description')
    },
    buttonLabel() {
      return this.translate('limitedAccess.button')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@mds/constants';
@import '@mds/typography';

.banner {
  background: linear-gradient(91.46deg, #00a8e1 0.7%, #6a4c9e 99.13%);
  width: 100%;
  padding: $mds-space-three-quarter-x 10px $mds-space-three-quarter-x 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $mds-space-1-x;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out 0s forwards;

  &__description {
    @include mds-body-text-s();
    color: $mds-text-color-primary-on-dark;
    margin: 0;
  }

  &__button {
    color: $mds-text-color-primary-on-dark !important;
    border-color: $mds-text-color-primary-on-dark;

    &::v-deep > [class^='mds-icon'] {
      fill: $mds-text-color-primary-on-dark !important;
      stroke: $mds-text-color-primary-on-dark !important;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
