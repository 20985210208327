import { feedManagerApi, alApi } from './api'
import { Notebook } from '@/types/notebook'
import { Parameters, Dataset } from '@/types/parameters'

export const getConfigNotebookId = async (
  titles: string[]
): Promise<string[] | null> => {
  try {
    const response = await alApi.get(
      `/config-notebooks?branch=${process.env.CONFIG_NOTEBOOK_REPO_BRANCH}`
    )
    const data = response.data

    const notebooksId = data.notebooks
      .filter((notebook: Notebook, index: number, array: any) => {
        return (
          titles.includes(notebook.notebook_name) &&
          array.findIndex(
            (elem: Notebook) => elem.notebook_name === notebook.notebook_name
          ) === index
        )
      })
      .map((element: Notebook) => ({
        id: element.id,
        notebook_name: element.notebook_name,
      }))
      .sort((a: Notebook, b: Notebook) =>
        a.notebook_name.localeCompare(b.notebook_name)
      )

    return notebooksId || null
  } catch (error) {
    return null
  }
}

export const getNotebookParameters = async (
  notebookId: string
): Promise<Parameters[]> => {
  try {
    const { data = {} } = await feedManagerApi.get(`/notebooks/${notebookId}`)
    return data.notebook_parameters
  } catch (error) {
    return []
  }
}

export const getBindingDataset = async (
  type: string,
  id: string
): Promise<Dataset> => {
  try {
    const { data = { dataset_id: '', name: '' } } = await feedManagerApi.get(
      `/datasets/${type}/${id}`
    )
    return data
  } catch (error) {
    return {
      dataset_id: '',
      name: '',
    }
  }
}
