export default {
  settings: {
    languageId: 'en',
  },
  labels: {
    en: {
      'notification.inProgressFeed.title': 'Feed preparation in progress',
      'notification.inProgressFeed.description':
        'The feed will be sent to the email associated with your account once the run is completed.',
      'notification.runningFeed.title': 'Feed preparation still in progress',
      'notification.runningFeed.description':
        'The previous feed run is in progress, please wait for the run to finish to trigger a new run.',
      'notification.error.title': 'Server Unavailable',
      'notification.error.description':
        'Sorry, the server is experiencing some difficulties. Please try again later.',
      'notification.errorDownload.title': 'Download Unavailable',
      'notification.errorDownload.description':
        'Sorry, there was an error retrieving the latest file. Please reach out to Customer Support for further assistance.',
      'notification.deleteFeed.title': 'Feed deleted',
      'notification.deleteFeed.description':
        'The feed has been successfully deleted.',
      'notification.deleteSchedule.title': 'Schedule deleted',
      'notification.deleteSchedule.description':
        'The schedule has been successfully deleted.',
    },
  },
}
