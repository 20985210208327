/* eslint-disable @typescript-eslint/no-explicit-any */
import { feedManagerApi } from './api'
import { ISchedule, IActivityLog, ICountryHoliday } from '@/types/schedules'

export const getSchedules = async (): Promise<ISchedule[]> => {
  try {
    const { data = [] } = await feedManagerApi.get('/schedules')
    return data
  } catch (error) {
    return []
  }
}

export const createFeedSchedule = async (
  feedId: number,
  scheduleInfo: ISchedule
): Promise<any> => {
  const { data } = await feedManagerApi.post(
    `/feeds/${feedId}/schedules`,
    scheduleInfo
  )
  return data
}

export const updateFeedSchedule = async (
  feedId: number,
  scheduleId: number,
  scheduleInfo: ISchedule
): Promise<any> => {
  const { data } = await feedManagerApi.put(
    `/feeds/${feedId}/schedules/${scheduleId}`,
    scheduleInfo
  )
  return data
}

export const getScheduleById = async (
  feedId: string,
  scheduleId: string
): Promise<ISchedule | undefined> => {
  try {
    const { data = {} } = await feedManagerApi.get(
      `/feeds/${feedId}/schedules/${scheduleId}`
    )
    return data
  } catch (error) {
    return undefined
  }
}

export const deleteById = async (scheduleId: number): Promise<boolean> => {
  try {
    await feedManagerApi.delete(`/feeds/schedules/${scheduleId}`)
    return true
  } catch (error) {
    return false
  }
}

export const getScheduleDownloadUrl = async (
  scheduleId: number
): Promise<string[] | undefined> => {
  try {
    const { data } = await feedManagerApi.get(
      `/feeds/schedules/${scheduleId}/presignedurl`
    )
    const { presigned_urls: presignedUrls = [] } = data
    return presignedUrls
  } catch (error) {
    return undefined
  }
}

export const getActivityLogsByScheduleId = async (
  feedId: number,
  scheduleId: number
): Promise<IActivityLog[]> => {
  try {
    const { data = [] } = await feedManagerApi.get(
      `/feeds/${feedId}/schedules/activity?schedule-id=${scheduleId}`
    )
    return data
  } catch (error) {
    return []
  }
}

export const getActivityLogs = async (
  feedId: number
): Promise<IActivityLog[]> => {
  try {
    const { data = [] } = await feedManagerApi.get(
      `/feeds/${feedId}/schedules/activity`
    )
    return data
  } catch (error) {
    return []
  }
}

export const getHolidays = async (): Promise<ICountryHoliday[]> => {
  try {
    const { data = {} } = await feedManagerApi.get('/holidays')
    return data.holidays
  } catch (error) {
    return []
  }
}
