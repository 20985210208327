var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banner" },
    [
      _c("p", {
        staticClass: "banner__description",
        domProps: { textContent: _vm._s(_vm.bannerDescription) },
      }),
      _vm._v(" "),
      _c("mds-button", {
        staticClass: "banner__button",
        attrs: {
          "on-dark": "",
          icon: "chat-bubble",
          variation: "secondary",
          target: "_blank",
          href: _vm.DATA_DISTRIBUTION_URL,
          text: _vm.buttonLabel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }