import { Route } from 'vue-router'
import { Store } from 'vuex'
import store from '@/store'
import { getTokenFromCookie } from '@/services/tokenDataProvider'
import { ICacheState } from '@/types/cache'

export const authGuard = async (
  to: Route,
  from: Route,
  next: (to?: string) => void
) => {
  const cacheStore = store as Store<ICacheState>

  const token =
    sessionStorage.getItem('FEED_MANAGER_TOKEN') || (await getTokenFromCookie())

  if (token) {
    cacheStore.commit('SET_TOKEN', token)
    next()
  } else {
    const sourceUrl = window.location.href
    window.location.replace(
      `${process.env.UIM_LOGIN_URL}?source=bus0224_AL&targetUrl=${sourceUrl}`
    )
  }
}
