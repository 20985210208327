var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "feed-manager__header" },
    [
      _c(
        "div",
        { staticClass: "feed-manager__head" },
        [
          _c("h1", {
            staticClass: "feed-manager__title",
            domProps: {
              textContent: _vm._s(_vm.translate("main.header.title")),
            },
          }),
          _vm._v(" "),
          _c("mds-button", {
            attrs: {
              variation: "icon-only",
              icon: "question-circle",
              type: "button",
              target: "_blank",
              href: _vm.supportUrl,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "mds-tabs",
        { staticClass: "feed-manager__nav" },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(
            "mds-tabs-item",
            {
              key: index,
              attrs: { active: tab.isActive },
              on: {
                "mds-tabs-item-active": function ($event) {
                  return _vm.$emit("tabClick", tab.id)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.translate(tab.name)) + "\n    ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }