export default {
  settings: {
    languageId: 'en',
  },
  labels: {
    en: {
      // Table headers
      'main.header.title': 'Feed Manager',
      'main.header.feeds': 'Feeds',
      'main.header.schedules': 'Schedules',
      'main.feeds.header.createFeed': 'Create Feed',
      'main.feeds.header.search': 'Search Feeds',
      'main.schedules.header.search': 'Search Schedules',
      // Column name for tables
      'table.header.name': 'Name',
      'table.header.id': 'Feed ID',
      'table.header.sourceType': 'Source Type',
      'table.header.lastUpdate': 'Last Update',
      'table.actions.export': 'Export',
      'table.actions.schedule': 'Schedule',
      'table.actions.download': 'Download',
      'table.header.status': 'Last Run Status',
      'table.header.lastRun': 'Last Run',
      'table.header.nextRun': 'Next Run',
      'table.header.recurrence': 'Recurrence',
      'table.header.latestFile': 'Latest File',
      // Export tooltip
      'table.export.tooltip.text':
        'Export and deliver a feed to your Direct account email.',
      // Row actions
      'table.actions.edit': 'Edit',
      'table.actions.delete': 'Delete',
      // Feedback table for feeds
      'table.feeds.empty.title': 'No feeds created yet',
      'table.feeds.empty.description':
        'Create your first feed. All your feeds can be monitored here.',
      // Feedback table for schedules
      'table.schedules.empty.title': 'No schedules created yet',
      'table.schedules.empty.description':
        'Create your first schedule. All your schedules can be monitored here.',
      // Feedback when a search is empty
      'table.notFound.title': 'No matches for this search',
      'table.notFound.description': 'Check for typos or try a new search.',
      // Dialog settings
      'dialog.delete.title': 'Are you sure you want to continue?',
      'dialog.feed.delete':
        'You are about to delete the current feed. This action cannot be undone.',
      'dialog.schedule.delete':
        'You are about to delete the current schedule. This action cannot be undone.',
      'dialog.feed.schedules.delete':
        'Deleting this feed will permanently remove all associated schedules.',
      'dialog.confirm.delete': 'Yes, I want to delete it.',
      'dialog.feed.schedules.confirm.delete': 'Yes, I want to delete all.',
      'dialog.feed.button.delete': 'Delete Feed',
      'dialog.feed.schedule.button.delete': 'Delete Feed and Schedule',
      'dialog.feed.schedules.button.delete': 'Delete Feed and Schedules',
      'dialog.schedules.button.delete': 'Delete Schedule',
      'dialog.delete.actions.cancel': 'No, Cancel',
      'dialog.feed.isRunning':
        'The feed is still running. You cannot delete it yet.',
    },
  },
}
