export enum Tabs {
  Feeds = 'feeds',
  Schedules = 'schedules',
}

export const FEEDS_TAB = Tabs.Feeds
export const SCHEDULES_TAB = Tabs.Schedules

const TABS = [
  {
    id: FEEDS_TAB,
    name: 'main.header.feeds',
    isActive: true,
  },
  {
    id: SCHEDULES_TAB,
    name: 'main.header.schedules',
    isActive: false,
  },
]

const STEPPER = [
  {
    id: 0,
    title: 'Source',
    active: true,
  },
  {
    id: 1,
    title: 'Parameters',
    active: false,
  },
  {
    id: 2,
    title: 'Format',
    active: false,
  },
  {
    id: 3,
    title: 'Delivery',
    active: false,
  },
]

export { TABS, STEPPER }
