import { feedManagerApi } from './api'

export const getUserSettings = async () => {
  try {
    const { data = {} } = await feedManagerApi.get('/user-settings')
    return data
  } catch (error) {
    return {}
  }
}
