import { feedManagerApi } from './api'
import { STATUS_IN_PROGRESS } from './config/constants'
import { IFeed } from '@/types/feeds'
import { ISchedule } from '@/types/schedules'
import { AxiosResponse } from 'axios'

export const getFeeds = async (): Promise<IFeed[]> => {
  try {
    const { data = [] } = await feedManagerApi.get('/feeds')
    return data
  } catch (error) {
    return []
  }
}

export const getFeedById = async (
  feedId: string
): Promise<IFeed | undefined> => {
  try {
    const { data = {} } = await feedManagerApi.get(`/feeds/${feedId}`)
    return data
  } catch (error) {
    return undefined
  }
}

export const deleteById = async (feedId: number): Promise<boolean> => {
  try {
    await feedManagerApi.delete(`/feeds/${feedId}`)
    return true
  } catch (error) {
    return false
  }
}

export const getSchedulesByFeedId = async (
  feedId: number
): Promise<ISchedule[]> => {
  try {
    const { data = [] } = await feedManagerApi.get(`/feeds/${feedId}/schedules`)
    return data
  } catch (error) {
    return []
  }
}

export const createFeed = async (postData: object): Promise<any> => {
  const url = `${process.env.DIRECT_FEED_MANAGER_BACKEND_URL}/feeds`
  const { data } = await feedManagerApi.post(url, postData)
  return data
}

export const updateFeed = async (
  feedId: string,
  postData: object
): Promise<any> => {
  const url = `${process.env.DIRECT_FEED_MANAGER_BACKEND_URL}/feeds/${feedId}`
  const { data } = await feedManagerApi.put(url, postData)
  return data
}

export const checkFeedStatus = async (feedId: string): Promise<boolean> => {
  try {
    const { data: schedules } = await feedManagerApi.get(
      `/feeds/${feedId}/schedules/activity`
    )
    for (const schedule of schedules) {
      if (schedule['status'] === STATUS_IN_PROGRESS) {
        return true
      }
    }
    return false
  } catch (error) {
    return false
  }
}

export const runFeedOnce = async (feedId: string): Promise<any> => {
  try {
    return await feedManagerApi.post(`/feeds/${feedId}/job`)
  } catch (error) {
    return (error as { response: AxiosResponse }).response
  }
}
