<template>
  <mds-notification-container
    position="top-center"
    class="feed-manager__notifications"
  >
    <mds-notification
      class="feed-manager__notification"
      size="small"
      v-for="(notification, id) in notifications"
      :key="id"
      :variation="notification.variation"
      :title="translate(notification.title)"
      @mds-notification-dismissed="onDismiss(notification)"
    >
      {{ translate(notification.description) }}
    </mds-notification>
  </mds-notification-container>
</template>
<script>
import { MdsNotification, MdsNotificationContainer } from '@mds/notification'
import MwcBaseHelpersMixin from '@morningstar/mcl-utilities/src/vue/mixins/mwc-base-mixin'
import defaultConfig from '@/components/config/i18n'

export default {
  name: 'FeedManagerNotifications',
  components: {
    MdsNotification,
    MdsNotificationContainer,
  },
  mixins: [MwcBaseHelpersMixin],
  props: {
    notifications: {
      type: Array,
    },
  },
  data() {
    return {
      defaultConfig,
    }
  },
  methods: {
    onDismiss({ id }) {
      this.$emit('dismissed', id)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@mds/constants';

.feed-manager {
  &__notification {
    &::v-deep > [class^='mds-notification__variation'] {
      position: absolute;
      height: 100%;
    }

    &::v-deep > [class^='mds-notification__body'] {
      padding-left: $mds-space-5-x;
      border-top-left-radius: $mds-space-1-x;
    }
  }
  &__notifications {
    pointer-events: all;
  }
}
</style>
