import isEmpty from 'lodash/isEmpty'
export interface IKeyValueType {
  [key: string]: string | object
}

const PARAMETERS_SOURCE_GRID = {
  notebookName: 'Grid View',
  parameters: 'gridViewParameters',
}

const PARAMETERS_SOURCE_PR = {
  notebookName: 'Performance Report',
  parameters: 'performanceReportParameters',
}

const INVESTMENT_LIST = 'Investment List'
const PERFORMANCE_REPORT = 'Performance Report'
const SEARCH_CRITERIA = 'Search Criteria'

const SOURCE_PARAMETERS: IKeyValueType = {
  investment_list: PARAMETERS_SOURCE_GRID,
  search_criteria: PARAMETERS_SOURCE_GRID,
  performance_report: PARAMETERS_SOURCE_PR,
}

const SOURCE_NAME: IKeyValueType = {
  investment_list: INVESTMENT_LIST,
  performance_report: PERFORMANCE_REPORT,
  search_criteria: SEARCH_CRITERIA,
}

export const formatInvestmentObject = (investmentObject: string) => {
  return investmentObject.toLowerCase().replace(/[-_ ]/g, '_')
}

export const getNotebookParameters = (source: string) => {
  if (isEmpty(source)) {
    return {}
  }

  return SOURCE_PARAMETERS[formatInvestmentObject(source)] ?? {}
}

export const getSourceName = (source: string) => {
  if (isEmpty(source)) {
    return ''
  }

  return SOURCE_NAME[formatInvestmentObject(source)]
}
